import React, { useState, useEffect } from "react";
import "./Support.scss";
import NavBar from "../../components/NavBar/NavBar";
import { supportData } from "../../data";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";

const Support = () => {
  const [openCategories, setOpenCategories] = useState([0]); // Initially open the first category
  const [selectedSubCategory, setSelectedSubCategory] = useState({
    categoryIndex: 0,
    subCategoryIndex: 0,
  });

  useEffect(() => {
    setSelectedSubCategory({ categoryIndex: 0, subCategoryIndex: 0 });
  }, []);

  const handleCategoryToggle = (index) => {
    if (openCategories.includes(index)) {
      setOpenCategories(openCategories.filter((i) => i !== index));
    } else {
      setOpenCategories([...openCategories, index]);
    }
  };

  const handleSubCategoryClick = (categoryIndex, subCategoryIndex) => {
    setSelectedSubCategory({ categoryIndex, subCategoryIndex });
  };

  const navLinks = [{ type: "page", to: "/", label: "Back" }];
  const { categoryIndex, subCategoryIndex } = selectedSubCategory;
  const selectedCategory = supportData[categoryIndex];
  const selectedSubCategoryData =
    selectedCategory.subCategories[subCategoryIndex];

  return (
    <div className="Support">
      <div className="sup-container-box">
        <div className="sp-container">
          <div className="bck-btn">
            <NavLink to={"/"}>
              <p>Back</p>
            </NavLink>
          </div>

          <div className="sp-title-container">
            <ul>
              {supportData.map((category, index) => {
                const isSelected = selectedSubCategory.categoryIndex === index;
                return (
                  <li
                    key={index}
                    className={`sp_section ${
                      openCategories.includes(index) || isSelected ? "open" : ""
                    } ${isSelected ? "selected" : ""}`}
                  >
                    <section
                      className="sp-title"
                      onClick={() => handleCategoryToggle(index)}
                    >
                      {category.section}
                    </section>
                    {(openCategories.includes(index) || isSelected) && (
                      <ul className="sp-sub-container">
                        {category.subCategories.map((subCategory, subIndex) => (
                          <Link
                            to={"filed-info"}
                            spy={true}
                            smooth={true}
                            duration={0}
                            activeClass="active"
                            offset={-180}
                          >
                            <li
                              key={subIndex}
                              className={`sp-sub-title ${
                                categoryIndex === index &&
                                subCategoryIndex === subIndex
                                  ? "selected"
                                  : ""
                              }`}
                              onClick={() =>
                                handleSubCategoryClick(index, subIndex)
                              }
                            >
                              {subCategory.question}
                            </li>
                          </Link>
                        ))}
                      </ul>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="sp-field">
            <div className="selected-content">
              <div className="sp-box-title" id="filed-info">
                {selectedSubCategoryData.question}
              </div>
              <div className="sp-box-container">
                {selectedSubCategoryData.text}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sup-footer">
        <div className="sup-container">
          <div className="footer-cmpny">FUNOONI</div>
          <div>support@funoonarab.com</div>
        </div>
      </div>
    </div>
  );
};

export default Support;
