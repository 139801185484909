import React from "react";
import "./Panel.scss";

const Panel = ({ title, text, img, ...attr }) => {
  const imgStyle = {
    background: `url(${img})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div className="Panel">
      <div className="img-item" style={imgStyle}/>
      <div className="panel-title">{title}</div>
      <div className="panel-text">{text}</div>
    </div>
  );
};

export default Panel;
