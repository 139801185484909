import React, { useState } from "react";
import "./OfferCard.scss";

const OfferCard = ({ title, description, pmntlink, artworkDesc, ...attr }) => {
  const [isClicked, setIsClicked] = useState(false);
  const [showDetails, setDetail] = useState(false);

  const handleDetail = () => {
    setDetail(!showDetails);
  };

  const handleAboutClick = () => {
    setIsClicked(!isClicked);
  };

  const handleArtClick = () => {
    setIsClicked(false);
  };

  const OfferCover = {
    background: `url(${artworkDesc.image})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div className="OfferCard ">
      <div className="offer-container">
        <div className={`offer-content ${isClicked ? "hidden" : ""}`}>
          <div className="offer-title">{title}</div>
          <div className="card-img" style={OfferCover}></div>
          {/* {showDetails ? <div className="offer-desc">{description}</div> : null}
          <div className="db-btn" onClick={handleDetail}>
            <p>{showDetails ? "HIDE" : "show details"}</p>
          </div> */}
        </div>

        <div className="btn-container">
          <a href={pmntlink}>
            <div className="offer-btn">
              <p>Buy Now</p>
            </div>
          </a>
          <div className="about-art-btn" onClick={handleAboutClick}>
            {isClicked ? "Back" : "About"}
          </div>
        </div>
        <div
          className={`artwork-section ${isClicked ? "visible" : ""}`}
          onClick={handleArtClick}
        >
          {/* <div
            className={`artworkImg ${isClicked ? "visible" : ""}`}
            style={OfferCover}
          ></div> */}
          <div className="artwork_token">
            <p>Token includes</p>
          </div>
          <div className="card-bundel">{artworkDesc.offer}</div>
          <ul className="artwork-desc">
            <li>
              <div className="artwork_open">
                <p>about the artwork</p>
              </div>
            </li>
            <li>
              <div className="desc-title">Name</div>{" "}
              <div className="desc-text-p">{artworkDesc.name}</div>
            </li>
            <li>
              <div className="desc-title">Artist</div> {artworkDesc.artist}
            </li>
            <li>
              <div className="desc-title">Size</div> {artworkDesc.size}
            </li>
            <li>
              <div className="desc-title">Edition</div> {artworkDesc.edition}
            </li>
          </ul>
          <div className="card-price">
            <div className="desc-title">Price</div> {artworkDesc.price}
          </div>

          <div className="spaceCard"></div>
        </div>
      </div>
    </div>
  );
};

export default OfferCard;
