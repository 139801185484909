import React from "react";
import "./Impact.scss";
import { impact_grapth } from "../../data";
import Infographic from "../../components/Infographic/Infographic";
import { cover } from "../../data";

const Impact = () => {
  return (
    <div className="impact-page">
        <div className="impc-cover" style={{ backgroundImage: `url(${cover})` }}></div>
        <div className="section-title"><p>Our Impact</p></div>
      <div className="container-imp">
        <Infographic/>

        
        <ul className="check-list">
          {impact_grapth.map((point, index) => (
            <li key={index} className="check-item">
              <div className="marks">
                <div className="bullet" />
                <div className="mark-title">{point.title}</div>
              </div>

              <div className="shape">
                <div className="stripe" />
                <div className="shape-text">{point.text}</div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="sup-footer">
        <div className="sup-container">
          <div className="footer-cmpny">FUNOONI</div>
          <div>support@funoonarab.com</div>
        </div>
      </div>
    </div>
  );
};

export default Impact;
