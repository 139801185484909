import React from "react";
import "./Home.scss";
import NavBar from "../../components/NavBar/NavBar";
import OfferCard from "../../components/OfferCard/OfferCard";
import Panel from "../../components/Panel/Panel";
import Container from "../../components/Container/Container";
import ActionPanel from "../../components/ActionPanel/ActionPanel";
import ContactForm from "../../components/ContactForm/ContactForm";
import { NavLink } from "react-router-dom";
import DropOffer from "../../components/DropOffer/DropOffer";

const OfferSection = ({ section, content, class_item }) => (
  <div className="field-item">
    <div className="separator-stripe">{section}</div>
    <ul className={`filed-grid ${class_item}`}>
      {content.map((item) => (
        <li className="grid-item" key={item.id}>
          <OfferCard
            artworkDesc={item.artwork}
            title={item.type}
            pmntlink={item.link}
          />
        </li>
      ))}
    </ul>
  </div>
);

const Home = ({ data, isAuthenticated }) => {
  const {
    textContent,
    imgArray,
    offerContent,
    artworkDesc,
    servDescription,
    purchasePlan,
    servicesList,
    infograph,
    icon_array_fgroup,
    icon_array_sgroup,
    icon_array_tgroup,
    icon_array_rgroup,
    prodOffer,
  } = data;

  const row3 = "row3"; // Определите классы, если они являются строками
  const row4 = "row4";

  return (
    <div className="Home">
      {/* <NavBar navLinks={navLinks} /> */}
      <header
        className="filter ton img-cont-aligm"
        style={{ backgroundImage: `url(${imgArray[6]})` }}
        id="section0"
      >
        <section className="headerCover">
          <h2>FUNOONI</h2>
          <p>Tokens for trees</p>
        </section>
      </header>

      <div className="Container algmnt" id="section2">
        <div className="separator-stripe"></div>

        <div className="container-header header-space">
          <Container
            title={textContent[0].title}
            text={textContent[0].textContent}
          />
        </div>

        <div className="box-shadow">
          <div className="box-contain">
            <div className="shadow-item box-item-1">
              <div
                className="shadow-box-img img-cont-aligm"
                style={{ backgroundImage: `url(${icon_array_tgroup[0]})` }}
              />
              <div className="shadow-item-contain">
                <div className="sh-item-title">{textContent[3].title}</div>
                <div className="sh-item-text">{textContent[3].textContent}</div>
              </div>
            </div>
            <div className="shadow-item box-item-2">
              <div
                className="shadow-box-img img-cont-aligm"
                style={{ backgroundImage: `url(${icon_array_tgroup[1]})` }}
              />
              <div className="shadow-item-contain">
                <div className="sh-item-title">{textContent[4].title}</div>
                <div className="sh-item-text">{textContent[4].textContent}</div>
              </div>
            </div>
          </div>
          {/* <a className="shadow-btn" href="https://holyfarmpalestine.com/">
            <p> Learn More About Our Partners</p>
          </a> */}
        </div>
        <ul className="infograph">
          {infograph.map((item, index) => (
            <li className="infograph-item" key={index}>
              <div className="img-box-item">
                <div
                  className="inf-img-item img-cont-aligm"
                  style={{
                    backgroundImage: `url(${icon_array_fgroup[index]})`,
                  }}
                />
              </div>
              <div className="arrow"></div>
              <div className="infograph-item-title">{item.name}</div>
            </li>
          ))}
        </ul>
      </div>

      <div className="Container algmnt" id="section3">
        <div className="container-header">
          <Container
            title={textContent[1].title}
            text={textContent[1].textContent}
          />
        </div>

        <OfferSection
          section={prodOffer[0].section}
          content={prodOffer[0].content}
        />

        {/* <div className="container-header">
          <Container
            title={textContent[5].title}
            text={textContent[5].textContent}
          />
        </div> */}
        <div className="space"></div>

        <OfferSection
          section={prodOffer[1].section}
          content={prodOffer[1].content}
        />
        <div className="separator-stripe" style={{marginTop:"3rem"}}>Plant a Tree Open Edition</div>
        <DropOffer isAuthenticated={isAuthenticated}/>
        <div className="offer-title-contain ">After Your Purchase</div>
        <ul className="purch-plan-list">
          {purchasePlan.map((plan, index) => (
            <li className="purch-item" key={index}>
              <Panel
                img={icon_array_sgroup[index]}
                title={plan.title}
                text={plan.text}
              ></Panel>
            </li>
          ))}
        </ul>
      </div>

      <div
        className="img-separator img-cont-aligm filter ton"
        style={{ backgroundImage: `url(${imgArray[7]})` }}
      >
        <div className="separator-text-box">{textContent[2].title}</div>
      </div>

      <div className="Container algmnt" id="section6">
        <ul className="segment-border">
          <li className="segment-field ">
            <div className="segment-title">{textContent[2].title}</div>
            <div className="text-contain">{textContent[2].textContent}</div>
          </li>
          <li className="segment-img">
            <div
              className="segment-image-item img-cont-aligm"
              style={{ backgroundImage: `url(${imgArray[9]})` }}
            />
            <div
              className="segment-image-item img-cont-aligm"
              style={{ backgroundImage: `url(${imgArray[10]})` }}
            />
            <div
              className="segment-image-item img-cont-aligm"
              style={{ backgroundImage: `url(${imgArray[8]})` }}
            />
          </li>
          <li className="segment-field  reverse">
            <div className="segment-title">{servDescription.title}</div>
          </li>
          <li className="segment-field  ">
            <div className="text-contain">{servDescription.desc1}</div>

            <ul className="serv_options">
              {servicesList.map((panel, index) => (
                <li className="serv_option_item" key={index}>
                  <ActionPanel
                    link={panel.link}
                    title={panel.name}
                    text={panel.description}
                    img={icon_array_rgroup[index]}
                  />
                </li>
              ))}
            </ul>

            <div className="text-contain extra">{servDescription.desc2}</div>
          </li>
        </ul>
      </div>
      <footer className="Container algmnt">
        <div className="separator-stripe">
          <div className="stripe-content">
            <section>Do you have any questions? - &nbsp;</section>
            <section>
              <NavLink to={"/support"}> FAQs</NavLink>
            </section>
          </div>
        </div>
        <ContactForm />
      </footer>
    </div>
  );
};

export default Home;
