import img1 from "./assets/IMG_7741.jpg";
import img2 from "./assets/IMG_7936.jpg";
import img3 from "./assets/IMG_06.jpg";
import img4 from "./assets/IMG_0003.jpg";
import img6 from "./assets/IMG_8069.jpg";
import img5 from "./assets/IMG_0004.jpg";
import img7 from "./assets/IMG_07.jpg";
import img8 from "./assets/IMG_08.jpg";
import img9 from "./assets/IMG_09.jpg";
import img10 from "./assets/IMG_10.jpg";
import img11 from "./assets/IMG_11.jpg";
import img12 from "./assets/IMG_12.jpg";
import img13 from "./assets/IMG_13.jpg";

import art1 from "./assets/IMG_0001.jpg";
import art2 from "./assets/IMG_0002.jpg";
import art3 from "./assets/IMG_0005.jpg";

import art5 from "./assets/update_01.jpg";
import art6 from "./assets/update_02.jpg";
import art7 from "./assets/Img_hope.jpeg";
import art8 from "./assets/img_promt.jpeg";
import art9 from "./assets/img34.jpeg";

import icon1 from "./assets/icon/icon_discord.png";
import icon2 from "./assets/icon/icon_email_bold.png";
import icon3 from "./assets/icon/icon_inst.png";

import s_icon1 from "./assets/soft_icons/ICO_TREE.png";
import s_icon2 from "./assets/soft_icons/ICO_FARM.png";
import s_icon3 from "./assets/soft_icons/ICO_LAND.png";
import s_icon4 from "./assets/soft_icons/ICO_ART.png";

import f_icon1 from "./assets/soft_icons/ICO_CERT.png";
import f_icon2 from "./assets/soft_icons/ICO_NFT_CERT.png";
import f_icon3 from "./assets/soft_icons/ICO_SHIP.png";
import f_icon4 from "./assets/soft_icons/ICO_WALLET.png";

import t_icon1 from "./assets/soft_icons/ICO_AIM.png";
import t_icon2 from "./assets/soft_icons/ICO_ROAD.png";

import r_icon1 from "./assets/soft_icons/ICO_BAZAR.png";
import r_icon2 from "./assets/soft_icons/ICO_TOKEN.png";
import r_icon3 from "./assets/soft_icons/ICO_ARTCHAIN.png";
import r_icon4 from "./assets/soft_icons/ICO_HUB.png";

import cover from "./assets/cover_2.jpg";

import imp_icon_1 from "./assets/icon_pack_2/High_cost.png";
import imp_icon_2 from "./assets/icon_pack_2/finances_chellenge.png";
import imp_icon_3 from "./assets/icon_pack_2/land.png";
import imp_icon_4 from "./assets/icon_pack_2/technology.png";
import imp_icon_5 from "./assets/icon_pack_2/water.png";

const icon_pack_2 = [
  imp_icon_5,
  imp_icon_3,
  imp_icon_1,
  imp_icon_2,
  imp_icon_4,
];

const imgArray = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
];

const icon_array_fgroup = [s_icon1, s_icon2, s_icon3, s_icon4];
const icon_array_sgroup = [f_icon1, f_icon2, f_icon3, f_icon4];

const icon_array_tgroup = [t_icon1, t_icon2];
const icon_array_rgroup = [r_icon1, r_icon2, r_icon3, r_icon4];

const textContent = [
  {
    index: 1,
    title: <p>About Our Tree Token Initiative</p>,
    textContent: (
      <p>
        Funooni collaborates with local Palestinian agricultural cooperatives,
        farmers, and producers to offer our global supporters a meaningful way
        to make a positive impact. By supporting our partnering farmers who have
        preserved their connection to the land for generations, we ensure they
        have access to financial stability, essential resources, protection
        against agricultural threats, and land preservation by utilizing
        blockchain technology.
        <br />
        <br /> Grow and adopt a tree in Palestine, documented in your name and
        represented as an exclusive NFT. Support local communities and enjoy our
        unique benefits.
      </p>
    ),
  },
  {
    index: 2,
    title: <p>Our Tree Tokens</p>,
    textContent: (
      <p>
        Join us in making a positive difference in the lives of those
        Palestinian farmers. Plant a tree with us and receive exclusive rewards:
        the caramel-like flavor of Medjool dates from Jericho, rich aroma of
        premium olive oil from Jenin, exclusiveNFT artwork from famous Levantine
        artists., and a 10% discount on any Funooni’s NFT Bazaar Artworks. 90%
        of the price of each Tree Token goes directly to the farmer, either by
        financial support or by providing them with the saplings and trees they
        need to plant.
      </p>
    ),
  },
  {
    index: 3,
    title: <p>More About Funooni </p>,
    textContent: (
      <p>
        Funooni is a cutting-edge project by{" "}
        <a className="simpl_link" href="https://funoonarab.com/">
          FunoonArab
        </a>
        , designed to spotlight artists and their creativity, with a special
        focus on traditional Arabic art. Whether you're a painter, photographer,
        sculptor, designer, or content creator, Funooni provides a dynamic stage
        to showcase your talent, connect with your audience, collaborate with
        fellow artists, and share your work globally. Funooni is where
        innovation meets tradition, allowing artists to explore, thrive, and
        make their mark in the creative universe.
      </p>
    ),
  },
  {
    index: 4,
    title: <p>Our Mission</p>,
    textContent: (
      <p>
        Our mission is to empower Palestinian farmers by preserving their land
        and livelihood through technology and blockchain solutions.
      </p>
    ),
  },
  {
    index: 5,
    title: <p>How Are We Going to Do It?</p>,
    textContent: (
      <p>
        We collaborate with local Palestinian farmers, producers and
        agricultural cooperatives who have been impacted by environmental,
        political and economic threats to sustain their lands, while engaging
        regional artists to create unique digital art for each tree token. By
        leveraging NFT technology, you can now grow and adopt a tree in your
        name and enjoy our exclusive benefits.
      </p>
    ),
  },
  {
    index: 6,
    title: <p>Grow a Tree with Funooni </p>,
    textContent: (
      <p>
        Join us in our mission to nurture the land and support local Palestinian
        farmers by planting a tree. Don't miss out on the chance to enjoy our
        exclusive rewards, celebrating our commitment to preserving our
        agricultural heritage for generations. Taste the Caramel-like flavor of
        fine Medjool dates from Jericho, savor the rich aroma of premium olive
        oil from Jenin, sourced directly from our dedicated farmers and
        producers, and collect unique and exclusive NFT artwork from famous
        levantine artists with every tree you plant.
      </p>
    ),
  },
];

const purchasePlan = [
  {
    title: <p>Tree Token</p>,
    text: (
      <p>
        Receive a unique Tree Token that represents your adopted tree on the
        blockchain, with its geographical location and coordinates in Palestine.
      </p>
    ),
  },
  {
    title: <p>NFT Artwork</p>,
    text: (
      <p>
        Receive limited edition digital art NFT inspired by the region, created
        by renowned levantine artists.
      </p>
    ),
  },
  {
    title: <p>Palestinian Produce Shipment</p>,
    text: (
      <p>Enjoy your reward of medjool dates or olive oil from your tree.</p>
    ),
  },
  {
    title: <p>Certificates</p>,
    text: (
      <p>
        Get digital tree ownership certificate for your tree token and
        certificate of authenticity for your NFT art.
      </p>
    ),
  },
];

const servicesList = [
  {
    name: <>NFT Bazaar</>,
    description: (
      <>
        Where we transform valuable physical artworks into unique digital
        assets, secured and authenticated via blockchain. Here, you can buy and
        sell these exclusive digital pieces, expanding your collection and
        engaging with a community of art lovers.
      </>
    ),
    link: "https://funooni.com",
  },
  {
    name: <>Tree Token</>,
    description: (
      <>
        Where you can support global reforestation and help sustain farming
        communities with our digital tokens that confirm your environmental
        impact.
      </>
    ),
  },
  {
    name: <>ArtChain</>,
    description: (
      <>
        Where you can discover a selection of authenticated physical art pieces
        that cater to both contemporary and traditional tastes.
      </>
    ),
  },
  {
    name: <>Heritage Hub</>,
    description: (
      <>
        A platform dedicated to preserving and celebrating cultural arts through
        advanced digitization, ensuring that precious heritage from all across
        the world is safeguarded for future generations.
      </>
    ),
  },
];

const offerContent = [
  {
    title: <p>Classic Tree Token</p>,
    description: (
      <p>
        Purchase an existing tree and receive a digital tree token as proof of
        your ownership and contribution to sustainability. You will receive 5 kg
        of Medjool dates annually from your tree. Additionally, you'll receive a
        limited edition NFT art piece and a certificate of authenticity from the
        artist.
      </p>
    ),
    link: "https://buy.stripe.com/00gaGB0LSeps5mo7th",
  },
  {
    title: <p>Premium Tree Token</p>,
    description: (
      <p>
        Purchase an existing tree and receive a digital tree token as proof of
        your ownership and contribution to sustainability. You will receive 5 kg
        of Medjool dates annually from your tree. Additionally, you'll receive a
        limited edition NFT art piece and a certificate of authenticity from the
        artist.
      </p>
    ),
    link: "https://buy.stripe.com/28obKFams6X00248xh",
  },
  {
    title: <p>Advanced Tree Token</p>,
    description: (
      <p>
        Purchase an existing tree and receive a digital tree token as proof of
        your ownership and contribution to sustainability. You will receive 10
        kg of Medjool dates annually from your tree. Additionally, you'll
        receive a limited edition NFT art piece and a certificate of
        authenticity from the artist.
      </p>
    ),
    link: "https://buy.stripe.com/4gw01Xamsdlo9CEfZK",
  },

  {
    title: <p>Cultivator's Choice Token</p>,
    description: (
      <p>
        Plant a new tree and receive a digital tree token as proof of your
        ownership. As a token of appreciation, you will receive 1kg of Medjool
        dates from the farmer's farm, one time. This option also includes a
        limited edition NFT art piece and a certificate of authenticity,
        celebrating your support for this noble cause.
      </p>
    ),
    link: "https://buy.stripe.com/eVaeWRamsdlo3eg5l8",
  },
];

const artworkDesc = [
  {
    key: "1",
    type: "classic",
    offer: (
      <ul className="card-offer-list">
        <li>
          <div className="point" />
          <div className="card-offer-item">
            <p>
              {" "}
              Digital tree token as proof of your ownership and contribution to
              sustainability.{" "}
            </p>
          </div>
        </li>
        <li>
          <div className="point" />
          <div className="card-offer-item">
            <p>
              Limited edition 1/100 printable NFT art piece and a certificate of
              authenticity from the artist
            </p>
          </div>
        </li>
        <li>
          <div className="point" />
          <div className="card-offer-item">
            <p>3 kg of Medjool dates annually from your tree for 5 years</p>
          </div>
        </li>
        <li>
          <div className="point" />
          <div className="card-offer-item">
            <p>10% discount on any of our NFT Bazaar Artworks</p>
          </div>
        </li>
      </ul>
    ),
    name: <p> أمير الحقول .. عروس العرب</p>,
    artist: <p>Ghazi Inaim</p>,
    size: <p>80×60</p>,
    edition: <p>1/100</p>,
    image: img13,
    price: "950 USD",
  },
  {
    key: "2",
    type: "premium",
    offer: (
      <ul className="card-offer-list">
        <li>
          <div className="point" />
          <div className="card-offer-item">
            <p>
              {" "}
              Digital tree token as proof of your ownership and contribution to
              sustainability.{" "}
            </p>
          </div>
        </li>
        <li>
          <div className="point" />
          <div className="card-offer-item">
            <p>
              Limited edition 1/100 printable NFT art piece and a certificate of
              authenticity from the artist
            </p>
          </div>
        </li>
        <li>
          <div className="point" />
          <div className="card-offer-item">
            <p>5 kg of Medjool dates annually from your tree for 7 years</p>
          </div>
        </li>
        <li>
          <div className="point" />
          <div className="card-offer-item">
            <p>10% discount on any of our NFT Bazaar Artworks</p>
          </div>
        </li>
      </ul>
    ),
    name: <p>تجريد</p>,
    artist: <p>Ghazi Inaim</p>,
    size: <p>75×75</p>,
    edition: <p>1/100</p>,
    image: art1,
    price: "1150 USD",
  },
  {
    key: "3",
    type: "classic",
    offer: (
      <ul className="card-offer-list">
        <li>
          <div className="point" />
          <div className="card-offer-item">
            <p>
              Digital tree token as proof of your ownership and contribution to
              sustainability.{" "}
            </p>
          </div>
        </li>
        <li>
          <div className="point" />
          <div className="card-offer-item">
            <p>
              Limited edition 1/100 printable NFT art piece and a certificate of
              authenticity from the artist.
            </p>
          </div>
        </li>
        <li>
          <div className="point" />
          <div className="card-offer-item">
            <p>10 kg of Medjool dates annually from your tree for 8 years</p>
          </div>
        </li>
        <li>
          <div className="point" />
          <div className="card-offer-item">
            <p>10% discount on any of our NFT Bazaar Artworks</p>
          </div>
        </li>
      </ul>
    ),
    name: <p>في هادئة الليل قلبي </p>,
    artist: <p>Ghazi Inaim</p>,
    size: <p>60×80</p>,
    edition: <p>1/100</p>,
    image: art2,
    price: "1650 USD",
  },
  {
    key: "4",
    type: "classic",
    offer: (
      <ul className="card-offer-list">
        <li>
          <div className="point" />
          <div className="card-offer-item">
            <p>
              Digital tree token as proof of your ownership and contribution to
              sustainability.
            </p>
          </div>
        </li>
        <li>
          <div className="point" />
          <div className="card-offer-item">
            <p>
              Limited edition 1/100 printable NFT art piece and a certificate of
              authenticity from the artist.
            </p>
          </div>
        </li>
        <li>
          <div className="point" />
          <div className="card-offer-item">
            <p>1kg of Medjool dates for one time only. </p>
          </div>
        </li>
        <li>
          <div className="point" />
          <div className="card-offer-item">
            <p>10% discount on any of our NFT Bazaar Artworks</p>
          </div>
        </li>
      </ul>
    ),
    name: <p>على تطريزات الطبيعة</p>,
    artist: <p>Ghazi Inaim</p>,
    size: <p>60×80</p>,
    edition: <p>1/100</p>,
    image: art3,
    price: "400 USD",
  },
  {
    key: "5",
    type: "classic",
    offer: (
      <ul className="card-offer-list">
        <li>
          <div className="point" />
          <div className="card-offer-item">
            <p>
              Digital tree token as proof of your ownership and contribution to
              sustainability.
            </p>
          </div>
        </li>
        <li>
          <div className="point" />
          <div className="card-offer-item">
            <p>
              Limited edition 1/300 printable NFT art piece and a certificate of
              authenticity from the artist.
            </p>
          </div>
        </li>
        <li>
          <div className="point" />
          <div className="card-offer-item">
            <p>750ml of Palestinian Premium Olive Oil for one time only.</p>
          </div>
        </li>
        <li>
          <div className="point" />
          <div className="card-offer-item">
            <p>10% discount on any of our NFT Bazaar Artworks</p>
          </div>
        </li>
      </ul>
    ),
    name: <p>أحبك يا هديل الأمسيات الخضر</p>,
    artist: <p>Ghazi Inaim</p>,
    size: <p>60×80</p>,
    edition: <p>1/300</p>,
    image: art5,
    price: "170 USD",
  },
  {
    key: "6",
    type: "classic",
    offer: (
      <ul className="card-offer-list">
        <li>
          <div className="point" />
          <div className="card-offer-item">
            <p>
              Digital tree token as proof of your ownership and contribution to
              sustainability.
            </p>
          </div>
        </li>
        <li>
          <div className="point" />
          <div className="card-offer-item">
            <p>
              Limited edition 1/300 printable NFT art piece and a certificate of
              authenticity from the artist.
            </p>
          </div>
        </li>
        <li>
          <div className="point" />
          <div className="card-offer-item">
            <p>1Liter of Palestinian Premium Olive Oil for one time only.</p>
          </div>
        </li>
        <li>
          <div className="point" />
          <div className="card-offer-item">
            <p>10% discount on any of our NFT Bazaar Artworks</p>
          </div>
        </li>
      </ul>
    ),
    name: <p>في ظلال الياسمين والزيتون</p>,
    artist: <p>Ghazi Inaim</p>,
    size: <p>35×60</p>,
    edition: <p>1/300</p>,
    image: art6,
    price: "200 USD",
  },
  {
    key: "7",
    type: "classic",
    offer: (
      <ul className="card-offer-list">
        <li>
          <div className="point" />
          <div className="card-offer-item">
            <p>
              Digital tree token as proof of your ownership and contribution to
              sustainability.
            </p>
          </div>
        </li>
        <li>
          <div className="point" />
          <div className="card-offer-item">
            <p>
              Limited edition 1/100 printable NFT art piece and a certificate of
              authenticity from the artist.
            </p>
          </div>
        </li>
      </ul>
    ),
    name: <p>The Ship and Hope</p>,
    artist: <p>Fadi Balhawan</p>,
    size: <p>35×45</p>,
    edition: <p>1/100</p>,
    image: art7,
    price: "140 USD",
  },
  // {
  //   key: "8",
  //   type: "classic",
  //   offer: (
  //     <ul className="card-offer-list">
  //       <li>
  //         <div className="point" />
  //         <div className="card-offer-item">
  //           <p>
  //             Digital tree token as proof of your ownership and contribution to
  //             sustainability.
  //           </p>
  //         </div>
  //       </li>
  //       <li>
  //         <div className="point" />
  //         <div className="card-offer-item">
  //           <p>
  //             Limited edition 1/100 NFT art piece and a certificate of
  //             authenticity from the artist.
  //           </p>
  //         </div>
  //       </li>
  //       <li>
  //         <div className="point" />
  //         <div className="card-offer-item">
  //           <p>2 kg of Medjool dates annually from your tree for 2 years</p>
  //         </div>
  //       </li>
  //       <li>
  //         <div className="point" />
  //         <div className="card-offer-item">
  //           <p>10% discount on any of our NFT Bazaar Artworks</p>
  //         </div>
  //       </li>
  //     </ul>
  //   ),
  //   name: <p>The Ship and Hope</p>,
  //   artist: <p>Fadi Balhawan</p>,
  //   size: <p>35×45</p>,
  //   edition: <p>1/100</p>,
  //   image: art8,
  //   price: "600 USD",
  // },
  {
    key: "8",
    type: "classic",
    offer: (
      <ul className="card-offer-list">
        <li>
          <div className="point" />
          <div className="card-offer-item">
            <p>
              Digital tree token as proof of your ownership and contribution to
              sustainability.
            </p>
          </div>
        </li>
        <li>
          <div className="point" />
          <div className="card-offer-item">
            <p>
              Limited edition 1/100 printable NFT art piece and a certificate of
              authenticity from the artist.
            </p>
          </div>
        </li>
        <li>
          <div className="point" />
          <div className="card-offer-item">
            <p>1kg of pickled olives & 1l of olive oil from your tree in December for 2 years</p>
          </div>
        </li>
        <li>
          <div className="point" />
          <div className="card-offer-item">
            <p>10% discount on any of our NFT Bazaar Artworks</p>
          </div>
        </li>
      </ul>
    ),
    name: <p>Which breezes will take us to a new dream</p>,
    artist: <p>Ghazi Inaim</p>,
    size: <p>60×80</p>,
    edition: <p>1/100</p>,
    image: art9,
    price: "600 USD",
  },
  
  
];

const servDescription = {
  title: <p>Our Services</p>,
  desc1: (
    <p>
      At Funooni, we are dedicated to blending the realms of traditional and
      digital art. Here’s a look at the diverse services we offer:
    </p>
  ),
  desc2: (
    <p>
      Artists interested in transforming their physical artwork into digital art
      or NFTs are invited to reach out via <b>support@funoonarab.com</b> for
      personalized assistance in entering the digital art market. The team will
      then get back to the artists with more information on the process and
      costs involved.
    </p>
  ),
};

const iconArray = [
  {
    title: "Discord",
    link: "https://discord.gg/2ekt8YmKDF",
    source: icon1,
  },
  {
    title: "social@funoonarab.com",
    link: "https://mail.google.com/mail/?view=cm&fs=1&to=social@funoonarab.com&su=Email%20Subject&body=Email%20Body",
    source: icon2,
  },
  {
    title: "Instagram",
    link: "https://www.instagram.com/funoonii/",
    source: icon3,
  },
];

const infograph = [
  {
    icon: "",
    name: <p>Buy a Tree</p>,
  },
  {
    icon: "",
    name: <p>Support the Farmer</p>,
  },
  {
    icon: "",
    name: <p>Protect the Land</p>,
  },
  {
    icon: "",
    name: <p>Empower the Artists</p>,
  },
];

const supportData = [
  {
    section: <p>Our Initiative</p>,
    subCategories: [
      {
        question: (
          <p>
            How does your initiative improve the livelihoods of local farmers?
          </p>
        ),
        text: (
          <p>
            By planting and owning a tree, you can actively support Palestinian
            farmers who face significant challenges including limited access to
            water, land, seeds, and finance. Through our tree token initiative,
            you can provide crucial cash flow to preserve their land, enhance
            farming techniques and facilities, and support their families and
            communities.
          </p>
        ),
      },
      {
        question: (
          <p>How can I ensure my contributions directly support the farmers?</p>
        ),
        text: (
          <p>
            Our team is committed to documenting and publishing success stories
            of the farmers we support, maintaining transparent records, and
            providing regular updates on how your support is utilized. We also
            collaborate with partners such as Holy Farm and PFCA to amplify our
            impact through their publications.
          </p>
        ),
      },
    ],
  },
  {
    section: <p>Supporting the Artists</p>,
    subCategories: [
      {
        question: <p>How do artists benefit from this project?</p>,
        text: (
          <p>
            With every Tree Token purchased, you receive a unique NFT and
            digital artwork from our featured artist, promoting their work.
            Additionally, a portion of the profits goes directly to the artist.
          </p>
        ),
      },
      {
        question: <p>What role do artists play in your initiative?</p>,
        text: (
          <p>
            This project connects artists and farmers using blockchain
            technology, placing our two main pillars at the heart of our
            mission. It not only provides financial support to our artists but
            also inspires them to share their stories, continue creating art,
            and promote their work.
          </p>
        ),
      },
    ],
  },
  {
    section: <p>The Tree</p>,
    subCategories: [
      {
        question: <p>What happens if my tree is destroyed?</p>,
        text: (
          <p>
            In case your tree is destroyed for any reason, we will replace it
            with a nearby tree for the remaining years of your purchasing plan.
            This ensures continuous support for the farmer and an uninterrupted
            supply of dates.
          </p>
        ),
      },
      {
        question: <p>How long will I receive a supply of dates?</p>,
        text: (
          <p>
            This depends on the purchase you make. For the Premium Tree Token,
            you will receive 10 kg annual supply of dates for 10 years. The
            Classic Tree Token offers you 5 kg annual supply for 10 years.
            Finally the Cultivator’s Choice Tree Token will offer you 1 kg for
            one time only.
          </p>
        ),
      },
      {
        question: <p>Can I visit the location where my tree is planted?</p>,
        text: (
          <p>
            Of course, we encourage our community to actively engage with
            farmers. While political restrictions may limit direct access to
            Palestine, you can always contact us or our partners to explore
            possibilities and arrange visits. Additionally, you can virtually
            visit your tree using the coordinates of the purchased or grown
            tree.
          </p>
        ),
      },
      {
        question: <p>What types of trees are planted?</p>,
        text: (
          <p>
            For the first phase of our project we are focusing on the palm
            sector, with plans to expand to other sectors in the future.
          </p>
        ),
      },
    ],
  },
  {
    section: <p>The Technology</p>,
    subCategories: [
      {
        question: <p>What is an NFT?</p>,
        text: (
          <p>
            Non-Fungible Tokens (NFTs) are unique tokens linked to digital and
            non-digital assets. Once minted on a blockchain decentralized
            network, they can't be reversed. Each token contains all properties
            related to the linked asset and can be used as a digital certificate
            of ownership. “Non-fungible” means it cannot be replaced,
            representing something unique in value - that's what an NFT is.
          </p>
        ),
      },
      {
        question: <p>Which platforms and cryptocurrencies are supported?</p>,
        text: (
          <p>
            Funooni’s NFT Bazaar utilizes smarts contracts built on Ethereum
            (ETH) network. We support Ethereum (ETH) as the primary
            cryptocurrency for buying, selling, and trading NFTs on our
            platform.
          </p>
        ),
      },
      {
        question: <p>Can I transfer my NFT to someone else?</p>,
        text: (
          <p>
            Yes, NFTs can be traded on any platform that supports blockchain
            technology. They can also be transferred from one digital wallet to
            another.
          </p>
        ),
      },
      {
        question: <p>How secure is the NFT technology used?</p>,
        text: (
          <p>
            Blockchain is generally a secure environment. However, owners need
            to protect their digital wallets just as they would protect their
            online banking accounts.
          </p>
        ),
      },
    ],
  },
];

const prodOffer = [
  // {
  //   section: <p>Adopt a tree</p>,
  //   content: [
  //     {
  //       id: "11",
  //       type: <p>Basic TREE TOKEN</p>,
  //       artwork: artworkDesc[7],
  //       link: "https://buy.stripe.com/9AQg0V8ek1CG4ik294",
  //     },
  //     {
  //       id: "12",
  //       type: <p>Classic Tree Token</p>,
  //       artwork: artworkDesc[0],
  //       link: "https://buy.stripe.com/00gaGB0LSeps5mo7th",
  //     },
  //     {
  //       id: "13",
  //       type: <p>Premium Tree Token</p>,
  //       artwork: artworkDesc[1],
  //       link: "https://buy.stripe.com/28obKFams6X00248xh",
  //     },
  //     {
  //       id: "14",
  //       type: <p>ADVANCED TREE TOKEN</p>,
  //       artwork: artworkDesc[2],
  //       link: "https://buy.stripe.com/4gw01Xamsdlo9CEfZK",
  //     },
  //   ],
  // },
  {
    section: <p>Adopt a tree</p>,
    content: [
      {
        id: "11",
        type: <p>Basic Olive TREE TOKEN</p>,
        artwork: artworkDesc[7],
        link: "https://buy.stripe.com/14k6ql528dlo3eg7tp",
      },
      {
        id: "12",
        type: <p>Classic  Palm Tree Token</p>,
        artwork: artworkDesc[0],
        link: "https://buy.stripe.com/00gaGB0LSeps5mo7th",
      },
      {
        id: "13",
        type: <p>Premium Palm Tree Token</p>,
        artwork: artworkDesc[1],
        link: "https://buy.stripe.com/28obKFams6X00248xh",
      },
      {
        id: "14",
        type: <p>ADVANCED PALM TREE TOKEN</p>,
        artwork: artworkDesc[2],
        link: "https://buy.stripe.com/4gw01Xamsdlo9CEfZK",
      },
    ],
  },
  {
    section: <p>Plant a tree</p>,
    content: [
      {
        id: "21",
        type: <p>Plant One Palm Tree</p>,
        artwork: artworkDesc[3],
        link: "https://buy.stripe.com/eVaeWRamsdlo3eg5l8",
      },
      {
        id: "22",
        type: <p>Plant One Olive Tree</p>,
        artwork: artworkDesc[4],
        link: "https://buy.stripe.com/8wM1611PWftwdSU28Y",
      },
      {
        id: "23",
        type: <p>Plant Two Olive Trees</p>,
        artwork: artworkDesc[5],
        link: "https://buy.stripe.com/fZe5mh0LSeps7uw00R",
      },
      {
        id: "24",
        type: <p>Plant One Olive Tree</p>,
        artwork: artworkDesc[6],
        link: "https://buy.stripe.com/fZe8ytcuAchk2ac9Bt",
      },
    ],
  },
];
const impact_grapth = [
  {
    title: <p>Ensuring Fairtrade Practices & Financial Stability</p>,
    text: (
      <p>
        We comply with Fairtrade practices and policies, and we pay our
        partnering farmers in advance to provide them with the financial
        stability they need to invest in their lands, plan for their futures,
        and improve the quality of their lives and their communities.
      </p>
    ),
  },
  {
    title: <p>Reinvesting in Vital Resources</p>,
    text: (
      <p>
        The funds from each Tree Roken purchase offer the farmers the
        opportunity to invest in critical resources that enable them to sustain
        and grow their produce, ensuring a more secure and sustainable future.
      </p>
    ),
  },
  {
    title: <p>Protecting Against Agricultural Threats </p>,
    text: (
      <p>
        Palestinian farmers not only face significant challenges inflicted by
        the Israeli government but also environmental challenges such as climate
        change and pest control issues. The financial support received via the
        Tree Token project allows farmers to invest in necessary treatments and
        preventive measures to combat these challenges, safeguarding their trees
        and ensuring healthy yields.
      </p>
    ),
  },
  {
    title: <p>Preserving and Protecting the Land</p>,
    text: (
      <p>
        Your support can also help Palestinian farmers protect their lands and
        preserve them for future generations. This not only enhances the
        livelihood of Palestinian farmers and rural communities but also
        maintains the rich agricultural heritage of Palestine and the region.
      </p>
    ),
  },
];

const impact_text = [
  {
    title: <p>Challenges Facing Palestinian Farmers</p>,
    text: (
      <p>
        Palestinian local farmers face many significant challenges including the
        inability to access essential resources such as water, land, technology,
        and finance. In 2023 alone, around 10,000 olive trees were left
        unharvested due to the movement restrictions imposed by the Israeli
        government on Palestinian farmers, further impacting over 80,000
        Palestinian families who depend on olive cultivation for their
        livelihood.
      </p>
    ),
  },
  {
    title: (
      <p>How Does Your Support Transform the Lives of Palestinian Farmers?</p>
    ),
    text: (
      <p>
        Our Tree Token project at Funooni aims to make a positive difference in
        the lives of those Palestinian farmers, all of whom are marginalised
        small-scale farmers who are affected by the daily violations and
        injustices inflicted by the Israeli occupation. We ensure that our
        partnering farmers have access to financial stability, necessary
        resources, protection against agricultural threats, and land
        preservation.<br/><br/> 90% of the price of each Tree Token goes directly to the
        farmer, either by cash flow or by providing them with the saplings and
        trees they need to plant. By purchasing a Tree Token from Funooni, you
        can also be part of this positive change.
      </p>
    ),
  },
];

export {
  imgArray,
  textContent,
  offerContent,
  artworkDesc,
  servDescription,
  iconArray,
  purchasePlan,
  servicesList,
  infograph,
  icon_array_fgroup,
  icon_array_sgroup,
  icon_array_tgroup,
  icon_array_rgroup,
  supportData,
  prodOffer,
  impact_grapth,
  impact_text,
  cover,
  icon_pack_2,
};
