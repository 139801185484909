import React, { useState } from 'react';
import "./Projects.scss";

const palmTree = 2;
const oliveTree = 6;

const stats = [
    {
        name: "Tree funded",
        number: `${palmTree + oliveTree} Trees`
    },
    {
        name: "Farmers supported",
        number: `3`
    },
    {
        name: "CO2 avoided",
        number: `${palmTree * 20 + oliveTree * 11.5} kg/year`
    }
];

const projectData = [
    {
        name: <p>Sarta</p>,
        about: <p>Planting 1000 Olive tree in the village of Sarta</p>,
        city: (
            <p>
                16 km west Salfit city, Sarta is edged by Qarawat Bani Hussan (north),
                Biddya (west), Haris (east) and Bruqin (south). The village has a total
                area of 5,615 dunums , of which 457 dunums are a built up area. Sarta is
                blocked by “Barkan” and “Ariel” settlements and so far the occupation
                confiscated (644) dunums of Sarta lands.
            </p>
        ),
        location: 1,
        goal: (
            <p>
                helping farmers plant 1,000 olive trees and providing them with all the
                farming tools they need to care for the trees.
            </p>
        ),
        climate_goal: <p>approximate CO2 Avoided 1.15 Tons</p>,
    },
    {
        name: <p>Planting 500 Palm Trees in the city of Jericho</p>,
        about: (
            <p>
                By collaborating alongside the Palm Tree Association in Palestine, we
                will facilitate for 250 Palm trees to be adopted in order to support
                Palestinian farmers.
            </p>
        ),
        city: (
            <p>
                Jericho, which is situated in the Jordan Valley in Palestine , west of
                the Jordan River and north of the Dead Sea, is known for its oasis-like
                surroundings, which are brought about by several natural springs. It was
                a major city in antiquity due to its advantageous placement along old
                trade routes. The name “Jericho” means “Moon City” in the Canaanite
                language.
            </p>
        ),
        location: 1,
        goal: (
            <p>
                helping farmers plant 500 palm trees and providing them with all the
                farming tools they need to care for the trees.
            </p>
        ),
        climate_goal: <p>approximate CO2 Avoided 20 Tons</p>,
    },
];

const Projects = () => {
    const [selectedProject, setSelectedProject] = useState(null);

    const handleProjectClick = (index) => {
        setSelectedProject(projectData[index]);
    };

    const handleClosePopup = () => {
        setSelectedProject(null);
    };

    return (
        <div className='projects-root'>
            <ul className='stats-container'>
                {stats.map((stat, index) => (
                    <li key={index} className='stats-item'>
                        <div className='stats-number'>
                            {stat.number}
                        </div>
                        <div className='stats-name'>
                            {stat.name}
                        </div>
                    </li>
                ))}
            </ul>

            <ul className='project-list'>
                {projectData.map((project, index) => (
                    <li key={index} className='project-item' onClick={() => handleProjectClick(index)}>
                        {project.name}
                    </li>
                ))}
            </ul>

            {selectedProject && (
                <div className='popup-overlay' onClick={handleClosePopup}>
                    <div className='popup-content' onClick={(e) => e.stopPropagation()}>
                        <h2>{selectedProject.name}</h2>
                        <div>{selectedProject.about}</div>
                        <div>{selectedProject.city}</div>
                        <div>{selectedProject.goal}</div>
                        <div>{selectedProject.climate_goal}</div>
                        <button onClick={handleClosePopup}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Projects;
