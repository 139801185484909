// App.js
import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.scss";
import Home from "./routes/Home/Home";
import Support from "./routes/Support/Support";
import * as data from "./data";
import NavBar from "./components/NavBar/NavBar";
import Impact from "./routes/Impact/Impact";
import { useState, useEffect } from "react";
import Footer from "./components/Footer/Footer";
import Legal from "./routes/Legacy/Legal";
import AuthForm from "./routes/AuthForm/AuthForm";
import Projects from "./routes/Projects/Projects";
import Verify from "./routes/AuthForm/Verify";

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const authStatus = sessionStorage.getItem('isAuthenticated') === 'true';
    setIsAuthenticated(authStatus);
  }, []);

  return (
    <div className="App">
      <ScrollToTop />
      <NavBar isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />
      <Routes>
        <Route path="/" element={<Home data={data} isAuthenticated={isAuthenticated} />} />
        <Route path="/support" element={<Support />} />
        <Route path="auth" element={<AuthForm setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/our_impact" element={<Impact />} />
        <Route path="verify-email" element={<Verify />} />
        <Route path="/our_projects" element={<Projects />} />
        <Route path="/legal/:section" element={<Legal />} />
        <Route path="/legal" element={<Legal />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
