import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const Verify = () => {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const { email } = location.state;

  const handleVerify = async (e) => {
    e.preventDefault();
    
    try {
      const response = await axios.post('https://treetoken-backend-104732163593.europe-west9.run.app/user/check_code', {
        email,
        code,
      });

      if (response.status === 200) {
        navigate('/', { state: { email } });
      }
    } catch (error) {
      setError('Verification failed. Please try again.');
    }
  };

  return (
    <div className="verify-email-form">
      <form onSubmit={handleVerify}>
        <h2>Verify your e-mail address</h2>
        <p>We sent a verification code to your e-mail.</p>
        <input
          type="text"
          placeholder="Enter verification code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          required
        />
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <button type="submit">Confirm</button>
      </form>
    </div>
  );
};

export default Verify;