import React from "react";
import "./Infographic.scss";
import { impact_text,icon_pack_2 } from "../../data";

const Infographic = () => {
  return (
    <div className="infographic">
      <div className="info-field">
        <div className="infographic-section">
          <h2>{impact_text[0].title}</h2>
        </div>

        <div className="infographic-section">
          <div className="infographic-item">
            <img src={icon_pack_2[0]} />
            <p>Limited access to water resources</p>
          </div>

          <div className="infographic-item">
            <img src={icon_pack_2[1]} />
            <p>Limited access to land</p>
          </div>

          <div className="infographic-item">
            <img src={icon_pack_2[2]} />
            <p>High production costs</p>
          </div>
          <div className="infographic-item">
            <img src={icon_pack_2[3]} />
            <p>Financial challenges</p>
          </div>
          <div className="infographic-item">
            <img src={icon_pack_2[4]} />
            <p>Limited access to Technology</p>
          </div>
        </div>
      </div>
      <div className="text-field">
        <div className="imp-title">{impact_text[1].title}</div>
        <div className="imp-buf">{impact_text[1].text}</div>
      </div>
    </div>
  );
};

export default Infographic;
