import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./AuthForm.scss";

const AuthForm = ({ setIsAuthenticated }) => {
  const [isSignUp, setIsSignUp] = useState(true);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleAuth = async (e) => {
    e.preventDefault();

    if (isSignUp && password !== repeatPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      if (isSignUp) {
        const response = await axios.post(
          "https://treetoken-backend-104732163593.europe-west9.run.app/user",
          {
            email,
            username,
            password: password,
          },
          { withCredentials: true } 
        );

        if (response.status === 201) {
          sessionStorage.setItem('isAuthenticated', 'true');
          setIsAuthenticated(true);
          navigate("/verify-email", { state: { email } });
        }
      } else {
        const response = await axios.post(
          "https://treetoken-backend-104732163593.europe-west9.run.app/user/auth",
          {
            email,
            password,
          },
          { withCredentials: true }  
        );

        if (response.status === 204) {
       
          sessionStorage.setItem('isAuthenticated', 'true');
          setIsAuthenticated(true);
          navigate("/", { state: { email } });
        }
      }
    } catch (error) {
      setError(isSignUp ? "Registration failed" : "Login failed");
    }
  };

  return (
    <div className="auth-space">
      <div className="auth-form">
        <div className="tab-buttons">
          <button
            className={isSignUp ? "active" : ""}
            onClick={() => setIsSignUp(true)}
          >
            Sign Up
          </button>
          <button
            className={!isSignUp ? "active" : ""}
            onClick={() => setIsSignUp(false)}
          >
            Log In
          </button>
        </div>

        <form onSubmit={handleAuth}>
          <h2>{isSignUp ? "Sign Up" : "Log In"}</h2>

          {isSignUp && (
            <input
              className="input-field"
              type="text"
              placeholder="User name"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          )}

          <input
            className="input-field"
            type="email"
            placeholder="E-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <input
            className="input-field"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />

          {isSignUp && (
            <input
              className="input-field"
              type="password"
              placeholder="Repeat password"
              value={repeatPassword}
              onChange={(e) => setRepeatPassword(e.target.value)}
              required
            />
          )}

          {isSignUp && (
            <>
              <label className="check-mark-field">
                <input className="check-mark" type="checkbox" required />{" "}
                <p className="mark-txt">I learned Terms of Use.</p>
              </label>
              <label className="check-mark-field">
                <input className="check-mark" type="checkbox" required />  <p className="mark-txt">I am
                not a Robot.</p>
              </label>
            </>
          )}

          {error && <p style={{ color: "red" }}>{error}</p>}
          {isSignUp ? <></> : <div className="space-reg"></div>}
          <button type="submit">{isSignUp ? "Verify" : "Log In"}</button>
        </form>
      </div>
    </div>
  );
};

export default AuthForm;