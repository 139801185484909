import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom"; 
import mainLogo from "../../assets/logo.png";
import BurgerButton from "./BurgerButton";
import PopupMenu, { Overlay } from "./PopupMenu";
import "./NavBar.scss";

const NavBar = ({ isAuthenticated, setIsAuthenticated }) => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [marginTop, setMarginTop] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation(); 

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const navbarHeight = 200;

    if (scrollY > navbarHeight) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const handleNavBarRef = useRef(null);

  useEffect(() => {
    if (handleNavBarRef.current) {
      setMarginTop(handleNavBarRef.current.clientHeight);
    }
  }, [isPopupOpen]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLogout = () => {
    sessionStorage.removeItem('isAuthenticated');
    setIsAuthenticated(false);
  };

  const logoImage = {
    background: `url(${mainLogo})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    transform: "scale(2)",
  };

  const navbarClass = isScrolled ? "nav-bar-buf scrolled" : "nav-bar-buf";

  return (
    <div className="NavBar">
      <div className={navbarClass} ref={handleNavBarRef}>
        <div className="nav-container">
          <div className="nav-upper">
            <Link to="/">
              <div className="inter_active_icon">
                <div className="nav-logo" style={logoImage}></div>
                <div className="nav-seporator_icon"></div>
                <div className="nav-company_title">funooni</div>
              </div>
            </Link>

            <div className="burger-menu">
              <BurgerButton onClick={togglePopup} />
            </div>
          </div>
        </div>
        <div className="nav-stroke">
          <ul className="nav-link-container">
            <li className="nav-point">
              <Link to="/" className="nv-link type2">
                <p>Home</p>
              </Link>
            </li>
            <li className="nav-point">
              <Link to="/support" className="nv-link type2">
                <p>FAQs</p>
              </Link>
            </li>
            <li className="nav-point">
              <Link to="/our_impact" className="nv-link type2">
                <p>Our Impact</p>
              </Link>
            </li>
            {/* <li className="nav-point">
              <Link to="/our_projects" className="nv-link type2">
                <p>Our Projects</p>
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
      {isPopupOpen && (
        <>
          <Overlay className="active" onClick={togglePopup} />
          <PopupMenu
            marginTop={marginTop}
            togglePopup={togglePopup}
            isAuthenticated={isAuthenticated}
            onLogout={handleLogout}
            email={sessionStorage.getItem('email')} 
          />
        </>
      )}
    </div>
  );
};

export default NavBar;
