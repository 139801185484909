import React, { useState } from "react";
import axios from "axios";
import "./DropOffer.scss";
import poster_01 from "../../assets/drop-image/Poster-6.png";
import poster_02 from "../../assets/drop-image/Poster-7.png";

const packages = [
  {
    title: "NFT for Planting One Olive Tree in Palestine",
    description:
      "NFT minted on the blockchain as a digital proof and record of your contribution towards sustainability and supporting Palestinian farmers. The artwork created by funooni.",
    imagePath: poster_01,
    price: "75 USD",
    link: "https://buy.stripe.com/4gw7up8ek2GK5mo9Cc",
  },
  {
    title: "NFT for Planting Five Olive Trees in Palestine",
    description:
      "NFT minted on the blockchain as a digital proof and record of your contribution towards sustainability and supporting Palestinian farmers. The artwork created by funooni.",
    imagePath: poster_02,
    price: "195 USD",
    link: "https://buy.stripe.com/8wMcOJ0LS1CGcOQg0B",
  },
];

const DropOffer = ({ isAuthenticated }) => {
  const [selectedPackage, setSelectedPackage] = useState(packages[0]); 
  const [email, setEmail] = useState("");
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const [fileName, setFileName] = useState('');

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    setFileName(file ? file.name : '');
  };

  const handlePackageSelect = (pkg) => {
    setSelectedPackage(pkg);
  };



  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!isAuthenticated) {
      setMessage('You need to log in to send an order.');
      return;
    }
  
    if (!file) {
      setMessage('Please upload a file.');
      return;
    }
  
    try {
      const formData = new FormData();
      formData.append('text_area', selectedPackage.title);
      formData.append('file', file);
  
      const response = await fetch('https://treetoken-backend-104732163593.europe-west9.run.app/custom_order', {
        method: 'POST',
        body: formData,
        credentials: 'include',
      });
  
      if (response.ok) {
        setMessage('Order successfully sent!');
      } else {
        setMessage('Failed to send order.');
      }
    } catch (error) {
      console.log('Error:', error);
      setMessage('Error while sending the order.');
    }
  };
  
  
  

  return (
    <div className="drop-offer">
      <p className="drop-text drop-format">
        Plant a tree in Palestine and receive a Funooni-designed poster, or use
        your own art, picture, or logo for a personalized poster of your
        choosing. You will also receive a digital tree token that includes all
        the details about your tree.
      </p>


      <div className="drop-container">
        <div className="offer-selection">
          {packages.map((pkg, index) => (
            <div
              key={index}
              className={`offer-package ${
                selectedPackage.title === pkg.title ? "selected" : ""
              }`}
              onClick={() => handlePackageSelect(pkg)}
            >
              <img src={pkg.imagePath} alt={pkg.title} />
              <p className="drop-text inner-format">{pkg.title}</p>
            </div>
          ))}
        </div>
        <div className="offer-details">
          <p className="drop-container-title">{selectedPackage.title}</p>
          <a className="drop-offer-bundle" href={selectedPackage.link}>
            <p>Plant a tree now</p>
          </a>
          <div className="separator">
            <div className="line" />
            <p className="key-word">or</p>
            <div className="line" />
          </div>

          <label className="file-upload-container">
        <input
          className="file-upload-input"
          type="file"
          onChange={handleFileChange}
          accept="image/*"
        />
        <span className="file-upload-label">Select your image</span>
      </label>
      {fileName && (
        <div className="file-upload-status">
          <p>Choosen file: <strong>{fileName}</strong></p>
        </div>
      )}

          <a className="drop-offer-bundle" onClick={handleSubmit}>
            <p>Plant a tree now</p>
          </a>

          {message && <p className="message">{message}</p>}
        </div>
      </div>
    </div>
  );
};

export default DropOffer;
