import React from 'react';
import styled from 'styled-components';

const BurgerButton = styled.button`
  user-select: none;
  background: none;
  border: none;
  cursor: pointer;
  
  span {
    color: black;
    font-size: 24px;
  }
`;

const Burger = ({ onClick }) => (
  <BurgerButton onClick={onClick}>
    <span>☰</span>
  </BurgerButton>
);

export default Burger;
