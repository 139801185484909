import React from 'react'
import "./Footer.scss"
import logo from "../../assets/logo.png"
import { Link } from 'react-router-dom'

const Footer = () => {
    const logoImg = {
        backgroundImage:`url(${logo})`,
        backgroundPosition:"center",
        backgroundSize:"cover",
        backgroundRepeat:"no-repeat",
        transfrom:"scale(2)",
    }
    return (
        <div className="Footer">
          <div className="footer-container">
            <div className="company-segment">
              <div className="company-logo" style={logoImg}></div>
              <div className="company-title-block">
                <div className="company-title">funooni</div>
                <div className="company-sub-title">You create. We elevate.</div>
              </div>
            </div>
            <div className="footer-info">
              <ul>
                <li>
                  <Link to={"/legal/terms-of-use"}>Terms of Use</Link>                  
                </li>
                <li>
                <Link to={"/legal/buyer-guarantee"}>Buyer Guarantee</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      );
}

export default Footer