import React from 'react'
import "./Container.scss"

const Container = ({title,text, ...attr}) => {
  return (
    <div className='container-box'>
      <div className="container-box-title">{title}</div>
      <div className="container-box-text">{text}</div>
    </div>
  )
}

export default Container
